* {
	margin: 0 0 0 0;
	padding: 0 0 0 0;
	font-family: Montserrat;
}
body {
	color: #DDDDDD;
	background-color: black;
}
nav {
	width: 100%;
	height: 5%;
	margin-top: 20px;
	margin-bottom: 7%;
}
nav a {
	width: calc(calc(100% / 3) - 2%);
	float: left;
	padding: 1%;
	text-align: center;
	text-decoration: none;
	color: white;
	position: relative;
	font-size: 200%;
}
nav a::after {
	position: absolute;
	content: "";
	height: 5%;
	bottom: 23%;
	left: 50%;
	right: 50%;
	background: #e76f51;
	transition: all .2s;
}
nav a:hover::after {
	left: 30%;
	right: 30%;
	transition: all .2s;
}
nav a:first-child {
	//margin-left: 0;
}
.root {
	display: flex;
	margin: auto;
	width: 90%;
	flex-direction: column;
	height: 100%;
}
.col {
	float: left;
	flex-direction: column;
	padding: 1%;
	margin: 1%;
}
.row {
	flex-direction: row;
	flex-grow: 1;
	display: flex;
}
h1 {
	color: #e76f51;
	text-align: center;
	font-size: 300%;
	margin-bottom: 3%;
}
p {
	font-size: 120%;
	text-align: center;
}
h2 {
	text-align: center;
	font-size: 200%;
}
a {
	color: white;
}
img {
	width: 100%;
	border-radius: 10px;
}
.card {
	flex-grow: 1;
	margin: 1%;
	padding: 1%;
}
.w50 {
	width: 46%;
}

@media only screen and (max-width: 1000px) and (min-width: 600px) {
	body {
		font-size: 75%;
	}
}

@media only screen and (max-width: 600px) {
	.col {
		width: 96%;
	}
	.row {
		flex-direction: column;
	}
	body {
		font-size: 50%;
	}
}
